import { default as updatecNUzkuliOUMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/[id]/update.vue?macro=true";
import { default as createMQXEPshL5tMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/create.vue?macro=true";
import { default as indexuM9X403nxPMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/index.vue?macro=true";
import { default as indexUyjQyH27cbMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/[id]/index.vue?macro=true";
import { default as updateATYDAW23hUMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/[id]/update.vue?macro=true";
import { default as createW1UNmtm2EQMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/create.vue?macro=true";
import { default as indexVhu1fkFhmxMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/index.vue?macro=true";
import { default as signinBWITlL74bpMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/auth/signin.vue?macro=true";
import { default as updateU6UigxTMJoMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/[id]/update.vue?macro=true";
import { default as createjTE3W6W0miMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/create.vue?macro=true";
import { default as indexJwyNRIJ5bSMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/index.vue?macro=true";
import { default as callsfZWpkN7pkSMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/dashboard/calls.vue?macro=true";
import { default as indexZr6VVyRTPsMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/[id]/index.vue?macro=true";
import { default as updatea2Q5L1VLWpMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/[id]/update.vue?macro=true";
import { default as createPZ3BJHY9g9Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/create.vue?macro=true";
import { default as index7z8Vba50TRMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/index.vue?macro=true";
import { default as updateFbAP5jVZEaMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/[id]/update.vue?macro=true";
import { default as createyfATenkriVMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/create.vue?macro=true";
import { default as indexDn6gRh0LyDMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/index.vue?macro=true";
import { default as updatezk8j1fisBrMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/[id]/update.vue?macro=true";
import { default as createlL9dQg1YSlMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/create.vue?macro=true";
import { default as indext406VRHWijMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/index.vue?macro=true";
import { default as inbox5MEvHrvKYhMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/inbox.vue?macro=true";
import { default as indexxUWqR5xUOkMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/index.vue?macro=true";
import { default as indexbveq0EqM60Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/[id]/index.vue?macro=true";
import { default as update459HYN9wB8Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/[id]/update.vue?macro=true";
import { default as createFSoD93whiUMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/create.vue?macro=true";
import { default as indexZefgvDD27mMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/index.vue?macro=true";
import { default as indexP9b7nZvVHxMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile/index.vue?macro=true";
import { default as settingsB0MCmE5RwsMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile/settings.vue?macro=true";
import { default as profileLoyggG3ZDKMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile.vue?macro=true";
import { default as indexHF3RdNQanOMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/index.vue?macro=true";
import { default as membersmsAq4MKsxHMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/members.vue?macro=true";
import { default as notifications1PavTAbVUZMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/notifications.vue?macro=true";
import { default as updatesSdFpOIJdAMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/[id]/update.vue?macro=true";
import { default as create2KU14etA8rMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/create.vue?macro=true";
import { default as index1MkF2AnDz4Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/index.vue?macro=true";
import { default as testJs3J7hOmdOMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/test.vue?macro=true";
import { default as updatems5fCGuGLEMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/[id]/update.vue?macro=true";
import { default as createGCwygyVGe7Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/create.vue?macro=true";
import { default as indexzdgO0GUVT4Meta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/index.vue?macro=true";
import { default as indexxuvqnx6T9NMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/[id]/index.vue?macro=true";
import { default as updateuJ18BjerWyMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/[id]/update.vue?macro=true";
import { default as createOAoHmqHZXoMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/create.vue?macro=true";
import { default as indexdlzZtquDVBMeta } from "/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/index.vue?macro=true";
export default [
  {
    name: updatecNUzkuliOUMeta?.name ?? "ads-id-update___ru",
    path: updatecNUzkuliOUMeta?.path ?? "/ads/:id()/update",
    meta: updatecNUzkuliOUMeta || {},
    alias: updatecNUzkuliOUMeta?.alias || [],
    redirect: updatecNUzkuliOUMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createMQXEPshL5tMeta?.name ?? "ads-create___ru",
    path: createMQXEPshL5tMeta?.path ?? "/ads/create",
    meta: createMQXEPshL5tMeta || {},
    alias: createMQXEPshL5tMeta?.alias || [],
    redirect: createMQXEPshL5tMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/create.vue").then(m => m.default || m)
  },
  {
    name: indexuM9X403nxPMeta?.name ?? "ads___ru",
    path: indexuM9X403nxPMeta?.path ?? "/ads",
    meta: indexuM9X403nxPMeta || {},
    alias: indexuM9X403nxPMeta?.alias || [],
    redirect: indexuM9X403nxPMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexUyjQyH27cbMeta?.name ?? "agencies-id___ru",
    path: indexUyjQyH27cbMeta?.path ?? "/agencies/:id()",
    meta: indexUyjQyH27cbMeta || {},
    alias: indexUyjQyH27cbMeta?.alias || [],
    redirect: indexUyjQyH27cbMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateATYDAW23hUMeta?.name ?? "agencies-id-update___ru",
    path: updateATYDAW23hUMeta?.path ?? "/agencies/:id()/update",
    meta: updateATYDAW23hUMeta || {},
    alias: updateATYDAW23hUMeta?.alias || [],
    redirect: updateATYDAW23hUMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createW1UNmtm2EQMeta?.name ?? "agencies-create___ru",
    path: createW1UNmtm2EQMeta?.path ?? "/agencies/create",
    meta: createW1UNmtm2EQMeta || {},
    alias: createW1UNmtm2EQMeta?.alias || [],
    redirect: createW1UNmtm2EQMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/create.vue").then(m => m.default || m)
  },
  {
    name: indexVhu1fkFhmxMeta?.name ?? "agencies___ru",
    path: indexVhu1fkFhmxMeta?.path ?? "/agencies",
    meta: indexVhu1fkFhmxMeta || {},
    alias: indexVhu1fkFhmxMeta?.alias || [],
    redirect: indexVhu1fkFhmxMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/agencies/index.vue").then(m => m.default || m)
  },
  {
    name: signinBWITlL74bpMeta?.name ?? "auth-signin___ru",
    path: signinBWITlL74bpMeta?.path ?? "/auth/signin",
    meta: signinBWITlL74bpMeta || {},
    alias: signinBWITlL74bpMeta?.alias || [],
    redirect: signinBWITlL74bpMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: updateU6UigxTMJoMeta?.name ?? "banners-id-update___ru",
    path: updateU6UigxTMJoMeta?.path ?? "/banners/:id()/update",
    meta: updateU6UigxTMJoMeta || {},
    alias: updateU6UigxTMJoMeta?.alias || [],
    redirect: updateU6UigxTMJoMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createjTE3W6W0miMeta?.name ?? "banners-create___ru",
    path: createjTE3W6W0miMeta?.path ?? "/banners/create",
    meta: createjTE3W6W0miMeta || {},
    alias: createjTE3W6W0miMeta?.alias || [],
    redirect: createjTE3W6W0miMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/create.vue").then(m => m.default || m)
  },
  {
    name: indexJwyNRIJ5bSMeta?.name ?? "banners___ru",
    path: indexJwyNRIJ5bSMeta?.path ?? "/banners",
    meta: indexJwyNRIJ5bSMeta || {},
    alias: indexJwyNRIJ5bSMeta?.alias || [],
    redirect: indexJwyNRIJ5bSMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/banners/index.vue").then(m => m.default || m)
  },
  {
    name: callsfZWpkN7pkSMeta?.name ?? "dashboard-calls___ru",
    path: callsfZWpkN7pkSMeta?.path ?? "/dashboard/calls",
    meta: callsfZWpkN7pkSMeta || {},
    alias: callsfZWpkN7pkSMeta?.alias || [],
    redirect: callsfZWpkN7pkSMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/dashboard/calls.vue").then(m => m.default || m)
  },
  {
    name: indexZr6VVyRTPsMeta?.name ?? "developers-id___ru",
    path: indexZr6VVyRTPsMeta?.path ?? "/developers/:id()",
    meta: indexZr6VVyRTPsMeta || {},
    alias: indexZr6VVyRTPsMeta?.alias || [],
    redirect: indexZr6VVyRTPsMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatea2Q5L1VLWpMeta?.name ?? "developers-id-update___ru",
    path: updatea2Q5L1VLWpMeta?.path ?? "/developers/:id()/update",
    meta: updatea2Q5L1VLWpMeta || {},
    alias: updatea2Q5L1VLWpMeta?.alias || [],
    redirect: updatea2Q5L1VLWpMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createPZ3BJHY9g9Meta?.name ?? "developers-create___ru",
    path: createPZ3BJHY9g9Meta?.path ?? "/developers/create",
    meta: createPZ3BJHY9g9Meta || {},
    alias: createPZ3BJHY9g9Meta?.alias || [],
    redirect: createPZ3BJHY9g9Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/create.vue").then(m => m.default || m)
  },
  {
    name: index7z8Vba50TRMeta?.name ?? "developers___ru",
    path: index7z8Vba50TRMeta?.path ?? "/developers",
    meta: index7z8Vba50TRMeta || {},
    alias: index7z8Vba50TRMeta?.alias || [],
    redirect: index7z8Vba50TRMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/developers/index.vue").then(m => m.default || m)
  },
  {
    name: updateFbAP5jVZEaMeta?.name ?? "geo-cities-id-update___ru",
    path: updateFbAP5jVZEaMeta?.path ?? "/geo/cities/:id()/update",
    meta: updateFbAP5jVZEaMeta || {},
    alias: updateFbAP5jVZEaMeta?.alias || [],
    redirect: updateFbAP5jVZEaMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createyfATenkriVMeta?.name ?? "geo-cities-create___ru",
    path: createyfATenkriVMeta?.path ?? "/geo/cities/create",
    meta: createyfATenkriVMeta || {},
    alias: createyfATenkriVMeta?.alias || [],
    redirect: createyfATenkriVMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/create.vue").then(m => m.default || m)
  },
  {
    name: indexDn6gRh0LyDMeta?.name ?? "geo-cities___ru",
    path: indexDn6gRh0LyDMeta?.path ?? "/geo/cities",
    meta: indexDn6gRh0LyDMeta || {},
    alias: indexDn6gRh0LyDMeta?.alias || [],
    redirect: indexDn6gRh0LyDMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/cities/index.vue").then(m => m.default || m)
  },
  {
    name: updatezk8j1fisBrMeta?.name ?? "geo-regions-id-update___ru",
    path: updatezk8j1fisBrMeta?.path ?? "/geo/regions/:id()/update",
    meta: updatezk8j1fisBrMeta || {},
    alias: updatezk8j1fisBrMeta?.alias || [],
    redirect: updatezk8j1fisBrMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createlL9dQg1YSlMeta?.name ?? "geo-regions-create___ru",
    path: createlL9dQg1YSlMeta?.path ?? "/geo/regions/create",
    meta: createlL9dQg1YSlMeta || {},
    alias: createlL9dQg1YSlMeta?.alias || [],
    redirect: createlL9dQg1YSlMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/create.vue").then(m => m.default || m)
  },
  {
    name: indext406VRHWijMeta?.name ?? "geo-regions___ru",
    path: indext406VRHWijMeta?.path ?? "/geo/regions",
    meta: indext406VRHWijMeta || {},
    alias: indext406VRHWijMeta?.alias || [],
    redirect: indext406VRHWijMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/geo/regions/index.vue").then(m => m.default || m)
  },
  {
    name: inbox5MEvHrvKYhMeta?.name ?? "inbox___ru",
    path: inbox5MEvHrvKYhMeta?.path ?? "/inbox",
    meta: inbox5MEvHrvKYhMeta || {},
    alias: inbox5MEvHrvKYhMeta?.alias || [],
    redirect: inbox5MEvHrvKYhMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: indexxUWqR5xUOkMeta?.name ?? "index___ru",
    path: indexxUWqR5xUOkMeta?.path ?? "/",
    meta: indexxUWqR5xUOkMeta || {},
    alias: indexxUWqR5xUOkMeta?.alias || [],
    redirect: indexxUWqR5xUOkMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbveq0EqM60Meta?.name ?? "pages-id___ru",
    path: indexbveq0EqM60Meta?.path ?? "/pages/:id()",
    meta: indexbveq0EqM60Meta || {},
    alias: indexbveq0EqM60Meta?.alias || [],
    redirect: indexbveq0EqM60Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: update459HYN9wB8Meta?.name ?? "pages-id-update___ru",
    path: update459HYN9wB8Meta?.path ?? "/pages/:id()/update",
    meta: update459HYN9wB8Meta || {},
    alias: update459HYN9wB8Meta?.alias || [],
    redirect: update459HYN9wB8Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createFSoD93whiUMeta?.name ?? "pages-create___ru",
    path: createFSoD93whiUMeta?.path ?? "/pages/create",
    meta: createFSoD93whiUMeta || {},
    alias: createFSoD93whiUMeta?.alias || [],
    redirect: createFSoD93whiUMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/create.vue").then(m => m.default || m)
  },
  {
    name: indexZefgvDD27mMeta?.name ?? "pages___ru",
    path: indexZefgvDD27mMeta?.path ?? "/pages",
    meta: indexZefgvDD27mMeta || {},
    alias: indexZefgvDD27mMeta?.alias || [],
    redirect: indexZefgvDD27mMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileLoyggG3ZDKMeta?.name ?? undefined,
    path: profileLoyggG3ZDKMeta?.path ?? "/profile",
    meta: profileLoyggG3ZDKMeta || {},
    alias: profileLoyggG3ZDKMeta?.alias || [],
    redirect: profileLoyggG3ZDKMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexP9b7nZvVHxMeta?.name ?? "profile___ru",
    path: indexP9b7nZvVHxMeta?.path ?? "",
    meta: indexP9b7nZvVHxMeta || {},
    alias: indexP9b7nZvVHxMeta?.alias || [],
    redirect: indexP9b7nZvVHxMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: settingsB0MCmE5RwsMeta?.name ?? "profile-settings___ru",
    path: settingsB0MCmE5RwsMeta?.path ?? "settings",
    meta: settingsB0MCmE5RwsMeta || {},
    alias: settingsB0MCmE5RwsMeta?.alias || [],
    redirect: settingsB0MCmE5RwsMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/profile/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexHF3RdNQanOMeta?.name ?? "settings___ru",
    path: indexHF3RdNQanOMeta?.path ?? "/settings",
    meta: indexHF3RdNQanOMeta || {},
    alias: indexHF3RdNQanOMeta?.alias || [],
    redirect: indexHF3RdNQanOMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membersmsAq4MKsxHMeta?.name ?? "settings-members___ru",
    path: membersmsAq4MKsxHMeta?.path ?? "/settings/members",
    meta: membersmsAq4MKsxHMeta || {},
    alias: membersmsAq4MKsxHMeta?.alias || [],
    redirect: membersmsAq4MKsxHMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: notifications1PavTAbVUZMeta?.name ?? "settings-notifications___ru",
    path: notifications1PavTAbVUZMeta?.path ?? "/settings/notifications",
    meta: notifications1PavTAbVUZMeta || {},
    alias: notifications1PavTAbVUZMeta?.alias || [],
    redirect: notifications1PavTAbVUZMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: updatesSdFpOIJdAMeta?.name ?? "tags-id-update___ru",
    path: updatesSdFpOIJdAMeta?.path ?? "/tags/:id()/update",
    meta: updatesSdFpOIJdAMeta || {},
    alias: updatesSdFpOIJdAMeta?.alias || [],
    redirect: updatesSdFpOIJdAMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create2KU14etA8rMeta?.name ?? "tags-create___ru",
    path: create2KU14etA8rMeta?.path ?? "/tags/create",
    meta: create2KU14etA8rMeta || {},
    alias: create2KU14etA8rMeta?.alias || [],
    redirect: create2KU14etA8rMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/create.vue").then(m => m.default || m)
  },
  {
    name: index1MkF2AnDz4Meta?.name ?? "tags___ru",
    path: index1MkF2AnDz4Meta?.path ?? "/tags",
    meta: index1MkF2AnDz4Meta || {},
    alias: index1MkF2AnDz4Meta?.alias || [],
    redirect: index1MkF2AnDz4Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/tags/index.vue").then(m => m.default || m)
  },
  {
    name: testJs3J7hOmdOMeta?.name ?? "test___ru",
    path: testJs3J7hOmdOMeta?.path ?? "/test",
    meta: testJs3J7hOmdOMeta || {},
    alias: testJs3J7hOmdOMeta?.alias || [],
    redirect: testJs3J7hOmdOMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/test.vue").then(m => m.default || m)
  },
  {
    name: updatems5fCGuGLEMeta?.name ?? "users-id-update___ru",
    path: updatems5fCGuGLEMeta?.path ?? "/users/:id()/update",
    meta: updatems5fCGuGLEMeta || {},
    alias: updatems5fCGuGLEMeta?.alias || [],
    redirect: updatems5fCGuGLEMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createGCwygyVGe7Meta?.name ?? "users-create___ru",
    path: createGCwygyVGe7Meta?.path ?? "/users/create",
    meta: createGCwygyVGe7Meta || {},
    alias: createGCwygyVGe7Meta?.alias || [],
    redirect: createGCwygyVGe7Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexzdgO0GUVT4Meta?.name ?? "users___ru",
    path: indexzdgO0GUVT4Meta?.path ?? "/users",
    meta: indexzdgO0GUVT4Meta || {},
    alias: indexzdgO0GUVT4Meta?.alias || [],
    redirect: indexzdgO0GUVT4Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexxuvqnx6T9NMeta?.name ?? "villages-id___ru",
    path: indexxuvqnx6T9NMeta?.path ?? "/villages/:id()",
    meta: indexxuvqnx6T9NMeta || {},
    alias: indexxuvqnx6T9NMeta?.alias || [],
    redirect: indexxuvqnx6T9NMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateuJ18BjerWyMeta?.name ?? "villages-id-update___ru",
    path: updateuJ18BjerWyMeta?.path ?? "/villages/:id()/update",
    meta: updateuJ18BjerWyMeta || {},
    alias: updateuJ18BjerWyMeta?.alias || [],
    redirect: updateuJ18BjerWyMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createOAoHmqHZXoMeta?.name ?? "villages-create___ru",
    path: createOAoHmqHZXoMeta?.path ?? "/villages/create",
    meta: createOAoHmqHZXoMeta || {},
    alias: createOAoHmqHZXoMeta?.alias || [],
    redirect: createOAoHmqHZXoMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/create.vue").then(m => m.default || m)
  },
  {
    name: indexdlzZtquDVBMeta?.name ?? "villages___ru",
    path: indexdlzZtquDVBMeta?.path ?? "/villages",
    meta: indexdlzZtquDVBMeta || {},
    alias: indexdlzZtquDVBMeta?.alias || [],
    redirect: indexdlzZtquDVBMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20241209202434/pages/villages/index.vue").then(m => m.default || m)
  }
]